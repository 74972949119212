import { useEffect, useRef, useState } from "react";
import { RootState } from "../app/store";
import { buildCanvas2dPipeline } from "../pipelines/canvas2d/canvas2dPipeline";
import { RenderingPipeline } from "../helpers/renderingPipelineHelper";
import { SegmentationConfig } from "../helpers/segmentationHelper";
import { SourcePlayback } from "../helpers/sourceHelper";
import { TFLite } from "../interfaces/componentsInterface/mediaInterface";
import { useAppSelector } from "../app/hooks";
import { videoOptionSelector } from "../features/videoOption/videoOptionSlice";
import { videoBackgroundSelector } from "../features/videoBackground/videoBackgroundSlice";

function useRenderingPipeline(
  sourcePlayback: SourcePlayback,
  segmentationConfig: SegmentationConfig,
  tflite: TFLite,
  showVideo: boolean | never|any
) {
  const videoOption = useAppSelector(videoOptionSelector)
  const backgroundSelector = useAppSelector(videoBackgroundSelector)

  const [pipeline, setPipeline] = useState<RenderingPipeline | null>(null);
  const backgroundImageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const canvasDisplayRef = useRef<HTMLCanvasElement>(null!);
  const [fps, setFps] = useState(0);
  const [durations, setDurations] = useState<number[]>([]);

  const image = new Image(); // Using optional size for image
  image.src = backgroundSelector.selectedBackground;
  image.crossOrigin = "anonymous"





  useEffect(() => {
    if (
      !showVideo ||
      videoOption.videoOption === "normal" ||
      !sourcePlayback ||
      !sourcePlayback.width ||
      !tflite
    )
      return;
    // The useEffect cleanup function is not enough to stop
    // the rendering loop when the framerate is low
    //============================================================
    let shouldRender = true;

    let previousTime = 0;
    let beginTime = 0;
    let eventCount = 0;
    let frameCount = 0;
    const frameDurations: number[] = [];

    let intervalId: number;

    let worker = new Worker("/js/bluringWorker.js");

    worker.postMessage({});

    //============================================================
    const newPipeline = buildCanvas2dPipeline(
      sourcePlayback,
      segmentationConfig,
      canvasRef.current,
      tflite,
      addFrameEvent,
      image,
      videoOption.videoOption
    );

    const ctxDisplay = canvasDisplayRef.current.getContext("2d");

    canvasDisplayRef.current.width = sourcePlayback.width / 5;
    canvasDisplayRef.current.height = sourcePlayback.height / 5;

    const canvas = canvasRef.current;

    worker.addEventListener("message", async (msg: any) => {
      beginFrame();
      await newPipeline.render();
      if (canvas && canvasDisplayRef && canvasDisplayRef.current)
        ctxDisplay?.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvasDisplayRef.current.width,
          canvasDisplayRef.current.height
        );
      endFrame();
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function render() {
      //============================================================
      if (!shouldRender) {
        return;
      }
      //============================================================
      intervalId = window.setInterval(async () => {
        beginFrame();
        await newPipeline.render();
        endFrame();
      }, 1000 / 15);
      // renderRequestId = requestAnimationFrame(render)
    }

    function beginFrame() {
      beginTime = Date.now();
    }

    function addFrameEvent() {
      const time = Date.now();
      frameDurations[eventCount] = time - beginTime;
      beginTime = time;
      eventCount++;
    }

    function endFrame() {
      const time = Date.now();
      frameDurations[eventCount] = time - beginTime;
      frameCount++;
      if (time >= previousTime + 1000) {
        setFps((frameCount * 1000) / (time - previousTime));
        setDurations(frameDurations);
        previousTime = time;
        frameCount = 0;
      }
      eventCount = 0;
    }

    // render();
    console.log("Animation started:", sourcePlayback, segmentationConfig);

    setPipeline(newPipeline);

    return () => {
      //============================================================
      shouldRender = false;
      // cancelAnimationFrame(renderRequestId)
      clearInterval(intervalId);
      worker.terminate();
      //============================================================
      newPipeline.cleanUp();
      console.log("Animation stopped:", sourcePlayback, segmentationConfig);

      setPipeline(null);
    };
  }, [
    sourcePlayback,
    segmentationConfig,
    tflite,
    showVideo,
    videoOption.videoOption,
    backgroundSelector.selectedBackground,
  ]);

  return {
    pipeline,
    backgroundImageRef,
    canvasRef,
    canvasDisplayRef,
    fps,
    durations,
  };
}

export default useRenderingPipeline;
